import * as React from "react";

import ResourcePage from "../components/resources/resources";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const Resource = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Ressources"
        description={t('learn_more_about_or_discover_best_practices')}
      />
      <Layout>
        <ResourcePage />
      </Layout>
    </>
  );
};

export default Resource;
