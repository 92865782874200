import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as classes from "./subscribe.module.scss";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Subscribe = () => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  const [email,setEmail] = useState('');
  const [emailError,setEmailError] = useState('');
  const [showResponseMsg, setShowResponseMsg] = useState('');

  const handleChange = (e) => {
    if (e.target.value) {
      if (!e.target.value) {
        setEmailError('email_is_required');
      } else if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        setEmailError('email_is_not_valid');
      } else {
        setEmailError('');
      }
    }
    setEmail(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowResponseMsg('please_wait');
    try {
      let personId;
      
      const searchResponse = await axios.get(
        'https://api.pipedrive.com/v1/persons/search',
        {
          params: {
            term: email,
            fields: 'email',
            exact_match: true,
            api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
          }
        }
      );

      if (searchResponse.data.data.items.length > 0) {
        personId = searchResponse.data.data.items[0].item.id;
        console.log('Existing person found:', searchResponse.data.data.items[0].item);
      } else{
        const personResponse = await axios.post(
          'https://api.pipedrive.com/v1/persons',
          {
            name: email,
            email: email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `8ce2444da077352e1e721778c927f15c93c3e7eb`
            },
            params: {
              api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
            }
          }
        );
        personId = personResponse.data.data.id;
      }

      const response = await axios.post(
        'https://api.pipedrive.com/v1/leads',
        {
          title:'Subscribe Email',
          '7dee32b1fb9960aec247945699f14d384d615478':email,
          channel_id:"Subscribe Email",
          owner_id: 21122988,
          origin_id:'04a8a400-135a-11ef-b183-d34b4389e43d',
          person_id: personId,
          organization_id: 20,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `8ce2444da077352e1e721778c927f15c93c3e7eb`
          },
          params: {
            api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
          }
        }
      );
  
      if(response){
        setShowResponseMsg('');
        window.open('https://d2lxkizvrhu4im.cloudfront.net/pdfs/Brochure_WebApp.pdf','_blank');
        setEmail('');
      }
    } catch (error) {
      setShowResponseMsg('something_went_wrong');
      console.error('Error creating deal:', error);
    }
  };
  
  return (
    <>
      <div
        className=" text-white flex flex-row items-center justify-center bg-no-repeat"
        style={{
          backgroundImage: `${
            breakpoints.md
              ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/work-better_bg.svg")`
              : `url("https://d2lxkizvrhu4im.cloudfront.net/images/bg2.svg")`
          }`
        }}
      >
        <div className="text-white flex flex-row items-end justify-center w-full">
          <div className=" hidden md:flex m-0 p-0 w-3/12">
            <img
              alt="subuscribe page img"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/message_girl.svg"
              className=""
            />
          </div>
          <div className="w-full  md:w-1/2 lg:px-10 mb-8 lg:my-10 2xl:my-20 flex flex-col items-center space-y-4 lg:space-y-4 mx-auto">
            <div
              className={`my-3 mt-14 text-3xl lg:text-5xl text-center lg:text-left font-semibold ${classes.title}`}
            >
              {t('our_brochure')}
            </div>
            <div
              className={`font-normal w-10/12 text-center lg:text-start ${classes.description}`}
            >
              {t('receive_our_new_brochure_with_details')}
            </div>
            <div className="text-base w-10/12 text-center lg:text-start subscribe_results_container"></div>
            <div className="w-11/12 space-y-3 subscribe_static_hide relative">
              <div className="w-full h-12 lg:h-14 bg-white bg-opacity-25 rounded-lg box-border flex flex-row items-center">
                <img
                  alt="subuscribe page img"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_message_icon.svg"
                  className="h-4 lg:h-5 m-3 md:m-2 lg:m-6 "
                />
                <input
                  id="subscribe_email_static"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleChange}
                  className="w-5/6 border-l-2 border-white bg-transparent placeholder-white px-2 lg:px-5 focus:outline-none"
                />
                <button
                  onClick={handleSubmit} 
                  disabled={emailError}
                  className={`${classes.subscribe_submit_static} bg-white flex items-center justify-center hidden h-full rounded-r-lg text-black font-bold md:text-sm text-center w-3/6 lg:flex flex-col justify-center cursor-pointer hover:shadow-lg`}>
                  {t('send')}
                </button>
              </div>
              <span style={{color:'red',fontSize:'13px',position:'absolute',left:'91px',bottom:'-15px'}}>{t(emailError)}</span>
              <p style={{fontSize:'16px',marginLeft:'127px',marginTop:'9px'}}>{t(showResponseMsg)}</p>
            </div>
          </div>
          <div className=" hidden md:flex m-0 p-0 w-3/12">
            <img alt="subuscribe page img" src="https://d2lxkizvrhu4im.cloudfront.net/images/message_girl_2.svg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
