import React, { useEffect, useRef } from "react";

import * as classes from "./videoModal.module.scss";

const VideoModal = ({ videoSrcURL, videoTitle, isOpen, setOpen, ...props }) => {

    const vidRef = useRef(null);
    
    useEffect(() => {
      const video = document.getElementById("video");
        if (isOpen === false) {
           
                vidRef?.current?.pause(); 
                
        } else {
            video.currentTime = 0;
            vidRef?.current?.play();
        }
    }, [isOpen])
  return (
    <div className={`${isOpen ? "block" : "hidden"}  ${classes.video}`}>
      <img alt="close icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg" onClick={() => setOpen(false)}  className={classes.closeButton}/>
      {/* <iframe
      src="/videos/clientsSectionVideo.mp4"
      title={videoTitle}
      allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      width= "100%"
      height="100%"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    /> */}

      <video
      id="video"
        // style={{objectFit:"", width:"100%", height:"100%"}}
         ref={vidRef}
        
        // onEnded={() => setShowPlayIcon(true)}
        autoPlay
      >
        <source src={videoSrcURL} type="video/mp4" />
      </video>
    </div>
  );
};
export default VideoModal;
