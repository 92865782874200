import React, { useState } from "react";
import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Subscribe from "../subscribe/subscribe";
import Subscribe2 from "../subscribe/subscribe_2";
import VideoModal from "../video/videoModal";
import { useTranslation } from 'react-i18next';

const Resources = () => {
  const {t} = useTranslation();
  const [videoUrl, setVideoUrl] = useState('');
  const [isOpen, setOpen] = useState(false);
  const breakpoints = useBreakpoint();

  const handleClick = (url) => {
    setOpen(true);
    setVideoUrl(url);
  };

  return (
    <>
      {
        videoUrl && <VideoModal isOpen={isOpen} setOpen={setOpen} videoSrcURL={videoUrl}/>
      }
      <div className={`conatiner font-sans `}>
        <div className="w-11/12 md:w-10/12  mx-auto my-5 md:my-10 space-y-3">
          <div className="text-center font-semibold text-gray-400 text-xs md:text-base">
            GUIDES ET RESSOURCES
          </div>
          <div className="text-3xl md:text-6xl font-bold text-center ">
          <h1>Ressources</h1>
          </div>
          <div className="text-sm md:text-xl text-center w-11/12 md:w-9/12 text-gray-500 mx-auto font-normal">
            {t('browse_the_list_of_digital_resources')} <br className="hidden md:block"/>
            {t('from_white_papers_to_case_studies')}
          </div>
        </div>
        <div className="w-11/12 md:w-10/12  mx-auto h-full flex flex-col md:flex-row justify-around">
          <Link
            to=""
            className="bg-yellow-400 w-full md:w-47 rounded-xl px-10 py-20 md:p-10 h-auto md:pb-52 mb-4 flex flex-col justify-center md:justify-start md:items-start items-center text-white hover:shadow-lg cursor-pointer"
          >
            <div className="text-yellow-100 opacity-50 font-bold text-xs md:text-base">
              {t('exams')}
            </div>
            <div className="text-2xl md:text-3xl  text-center md:text-left font-bold ">
              {t('myths_and_facts')}
            </div>
          </Link>
          <div className="w-full md:w-1/2 flex flex-col space-y-4">
            <div className="w-full flex flex-col md:flex-row md:space-x-4 ">
              <Link to=""
                className="w-full md:w-47 border-2 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg cursor-pointer"
                // style={{ backgroundImage: `url("images/card_bg.svg")` }}
              >
                {" "}
                <div className="text-xs text-gray-500 font-extrabold my-2">{t('recritment_test')}</div>
                <div className="text-sm md:text-lg font-bold">{t('how_to_identify_the_best')}</div>
              </Link>
              <Link to=""
                className="w-full md:w-47 border-2 border-yellow-300 rounded-xl bg-yellow-50 p-5 box-border font-bold md:pb-52 mb-4 bg-cover hover:shadow-lg cursor-pointer"
                // style={{ backgroundImage: `url("images/card_bg.svg")` }}
              >
                {" "}
                <div className="text-xs text-gray-500 font-extrabold my-2 uppercase">{t('admission_test')}</div>
                <div className="text-sm md:text-lg font-bold">{t('from_lead_to_registration')} </div>
              </Link>
            </div>
            <div className="w-full flex flex-col md:flex-row md:space-x-4 ">
              <Link to=""
                className="w-full md:w-47 border-2 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg cursor-pointer"
                // style={{ backgroundImage: `url("images/card_bg.svg")` }}
              >
                {" "}
                <div className="text-xs text-gray-500 font-extrabold my-2">{t('comparator')} </div>
                <div className="text-sm md:text-lg font-bold">{t('choosing_your_proctoring_tool')} </div>
              </Link>
              <Link to=""
                className="w-full md:w-47 border-2 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg cursor-pointer"
                // style={{ backgroundImage: `url("images/card_bg.svg")` }}
              >
                {" "}
                <div className="text-xs text-gray-500 font-extrabold my-2">{t('private_data')}</div>
                <div className="text-sm md:text-lg font-bold">{t('mereos_and_the_gdpr')}</div>
              </Link>
            </div>
          </div>
        </div>

        <div className=" pb-10 my-10">
        {breakpoints.md?<Subscribe2 />:<Subscribe />}
          
        </div>

        <div className="w-11/12 md:w-10/12 mx-auto  flex flex-col justify-between md:my-20">
          <div className="flex flex-col space-y-5 my-10">
          <div className="text-center text-3xl md:text-5xl font-extrabold md:font-semibold ">
            {t('videos')} 
          </div>
          <div className="text-center mx-2 md:mx-0  text-sm md:text-base text-gray-500 ">
            {t('understand_the_key_features')}
          </div>
          </div>
          <div className="w-full flex flex-col md:flex-row  flex-wrap justify-between ">
            <div
              onClick={() => handleClick('https://www.youtube.com/watch?v=dNsvifSHzD8')}
              className="w-full md:w-31 border-2 h-32 md:h-120 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg "
                // style={{ backgroundImage: `url("images/card_bg.svg")` }}
            >
              <div className="text-xs text-gray-500 font-extrabold my-2 uppercase">
                {t('products')}
              </div>
              <div className="text-sm md:text-2xl font-bold">
                {t('what_is_an_online_exam')}
              </div>
            </div> 
            <Link
              to=""
              className="w-full md:w-31 border-2 border-yellow-300 h-32 md:h-120 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg"
            >
                <div className="text-xs text-gray-500 font-extrabold my-2 uppercase">
                  {t('data_protection')}
                </div>
                <div className="text-sm md:text-2xl font-bold">
                  {t('how_does_mereos_protect')}
                </div>
            </Link>
            <Link
              to=""
              className="w-full md:w-31 border-2 h-32 md:h-120 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg"
            >
              <div className="text-xs text-gray-500 uppercase font-extrabold my-2">
                {t('automated_monitoring')}
              </div>
              <div className="text-sm md:text-2xl font-bold">
                {t('how_artificial_intelligence_is_revolutionizing')}
              </div>
            </Link>
          </div>
        </div>

        <div className="w-11/12 md:w-10/12 mx-auto flex flex-col justify-between md:my-20 ">
          <div className="flex flex-col space-y-5 my-10">
            <div className="text-center font-extrabold md:font-bold text-3xl  md:text-5xl">
              Brochures
            </div>
            <div className="text-center text-sm md:text-base mx-2 md:mx-0 md:mb-10">
              {t('guides_to_meet_the_challenges')}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row  flex-wrap justify-between ">
            <Link
              to=""
              className="w-full md:w-31 border-2 h-32 md:h-120 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg"
            >
              <div className="text-xs text-gray-500 uppercase font-extrabold my-2">
                {t('platform')}
              </div>
              <div className="text-sm md:text-2xl font-bold">
                {t('the_mereos_secure_evaluation_platform')}
              </div>
            </Link>
            <Link
              to=""
              className="w-full md:w-31 border-2 h-32 md:h-120 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg"
            >
              <div className="text-xs text-gray-500 font-extrabold my-2">
                EXTENSION
              </div>
              <div className="text-sm md:text-2xl font-bold">
                {t('the_mereos_extension')}
              </div>
            </Link>
            <Link
              to=""
              className="w-full md:w-31 border-2 h-32 md:h-120 border-yellow-300 rounded-xl p-5 box-border font-bold md:pb-52 mb-4 bg-cover bg-yellow-50 hover:shadow-lg"
            >
                <div className="text-xs text-gray-500 font-extrabold my-2">
                  {t('content')}
                </div>
                <div className="text-sm md:text-2xl font-bold">
                  {t('the_mereos_educational_design')}
                </div>
            </Link>
          </div>
        </div>

       
        {/* Bottom Banner */}
        <Subscribe2 />
      </div>
    </>
  );
};

export default Resources;
